<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
            class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title" style="padding-bottom: inherit">
            <h3 class="card-label">
              {{ $t('INPUTS.ORDER_REGISTRATION') }}
            </h3>
          </div>
        </div>

        <div class="card-body">
          <form v-on:submit.prevent="submitzayavka">
            <div class="row">
              <div class="col-6" style="padding-top: inherit; margin-top: inherit; height: 10px !important;">
                <v-autocomplete
                    v-model="combobox"
                    @change="getClientContracts"
                    v-debounce:200ms="myFn"
                    :items="allClientNames"
                    :label="$t('INPUTS.CLIENT_NAME')"
                    item-text="full_name"
                    item-value="id"
                    hide-selected
                    dense
                    no-data-text="Malumot topilmadi"
                    outlined
                    return-object
                    :loading="isLoading"
                ></v-autocomplete>
              </div>

              <div class="col-6" style="margin-top: inherit; padding-top: inherit">
                <v-select
                    @change="clientContractsDetail(combobox2)"
                    v-model="combobox2"
                    :items="contractNumbers"
                    item-text="contract_number"
                    item-value="id"
                    :label="$t('TABLE_HEADER.CONTRACT')"
                    outlined
                    dense
                    :error-messages="clientContractErrors"
                    no-data-text="Malumot topilmadi"
                    @input="$v.combobox2.$touch()"
                    @blur="$v.combobox2.$touch()"
                ></v-select>
              </div>
            </div>
            <div v-if="combobox.juridical_type == 'JI'">
              <div class="form-group" style="margin-top: inherit; padding-top: inherit;">
                <v-container fluid>
                  <v-row justify="space-between">
                    <v-col cols="4" class="border">
                      <label> {{ $t('TABLE_HEADER.DIRECTION') }} </label>
                      <v-autocomplete
                          @input="setCities"
                          outlined
                          hide-selected
                          :items="AllCities"
                          item-text="name"
                          item-value="id"
                          dense
                          return-object
                          v-model="city"
                          :loading="isLoading"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="4" class="border">
                      <label>Shaharlar</label>
                      <draggable
                          :list="cities"
                          class="list-group"
                          group="people"
                      >
                        <div
                            :class="
                            ++index == '1'
                              ? 'bg-white border my-1'
                              : 'bg-white border my-1'
                          "
                            class="list-group-item"
                            v-for="(element, index) in cities"
                            :key="element.name"
                        >
                          {{ element.name }}
                          <span
                              v-if="index == 1"
                              class="
                              f-right
                              label label-lg label-light-success label-inline
                            "
                          >Boshlangich manzil
                            {{ (element.order = index) }}</span
                          >
                          <span
                              v-else
                              class="
                              f-right
                              label label-lg label-light-warning label-inline
                            "
                          >Manzil {{ (element.order = index) }}</span
                          >
                        </div>
                      </draggable>
                    </v-col>
                    <v-col cols="4 " class="px-50 m-auto">
                      <draggable
                          group="people"
                          :list="cities2"
                          class="list-group"
                      >
                        <div
                            class="col bg-light-danger px-6 py-8 rounded-xl mr-7"
                        >
                          <span
                              class="
                              svg-icon svg-icon-danger svg-icon-2x
                              d-block
                              my-2
                            "
                          ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Home/Trash.svg--><svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                          >
                              <g
                                  stroke="none"
                                  stroke-width="1"
                                  fill="none"
                                  fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"/>
                                <path
                                    d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z"
                                    fill="#000000"
                                    fill-rule="nonzero"
                                />
                                <path
                                    d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000"
                                    opacity="0.3"
                                />
                              </g></svg
                          ><!--end::Svg Icon--></span
                          >
                          <a
                              href="#"
                              class="
                              text-danger
                              font-weight-bold font-size-h6
                              mt-2
                            "
                          >Shahar (O'chirish)</a
                          >
                        </div>
                      </draggable>
                    </v-col>
                    <!-- <v-col cols="5" class="border">
                      <label>Yuk mashina turi</label>
                      <v-autocomplete
                        outlined
                        hide-selected
                        :items="truckList"
                        item-text="truck__name"
                        item-value="truck_type.id"
                        dense
                        v-model="truck_1"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="5" class="border">
                      <label>Yukmashinani tanlang</label>
                      <v-autocomplete
                        outlined
                        hide-selected
                        :items="truckTypeList"
                        item-text="item"
                        item-value="id"
                        dense
                        return-object
                        v-model="driverTruckId"
                      ></v-autocomplete>
                    </v-col> -->
                  </v-row>
                </v-container>
              </div>
              <div class="row">
                <div
                    class="col-12 row align-items-center justify-content-between"
                    style="padding-top: inherit; margin-top: inherit"
                >
                  <div style="margin-top: inherit; padding-top: inherit">
                    <h3>Summa</h3>
                  </div>

                  <div>
                    <v-switch v-model="switch2" label="Nds"></v-switch>
                  </div>
                </div>
                <div class="col-4" style="padding-top: inherit; margin-top: inherit">
                  <v-text-field
                      class="mb-0"
                      dense
                      v-currency="options"
                      v-model="truckIdPrice"
                      label="Narxi"
                      outlined
                  ></v-text-field>
                </div>
                <div class="col-4" style="margin-top: inherit; padding-top: inherit">
                  <v-text-field
                      class="mb-0"
                      :value="truckPriceNds1"
                      disabled
                      label="Nds"
                      dense
                      outlined
                  ></v-text-field>
                </div>
                <div class="col-4" style="padding-top: inherit; margin-top: inherit">
                  <v-text-field
                      class="mb-0"
                      disabled
                      dense
                      v-model="truckPriceWithNds1"
                      label="Narx(qqs)"
                      outlined
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div
                    class="col-6 align-self-end"
                    style="margin-top: inherit; padding-top: inherit; height: 50px !important;"
                    v-for="i in maxCityNumber(contractDetails)"
                    :key="i"
                    :class="i == 1 ? '' : i == 2 ? '' : 'offset-6'"
                    v-show="
                    i == 1 ||
                    citydestination[i - 1] !== undefined ||
                    getByOrder(contractDetails, i).length == 0
                  "
                >
                  <div v-if="getByOrder(contractDetails, i).length !== 0">
                    <v-select
                        v-model="citydestination[i]"
                        :items="getByOrder(contractDetails, i)"
                        item-text="name"
                        item-value="city_id"
                        dense
                        :label="$t('TABLE_HEADER.DIRECTION') + ' ' + i + ' '"
                        return-object
                        no-data-text="Malumot topilmadi"
                        outlined
                    ></v-select>
                  </div>
                </div>
              </div>
              <div class="row" style="padding-top: inherit; margin-top: inherit; height: 65px !important;">
                <div class="col-12">
                  <v-select
                      v-model="truckId"
                      @change="getTruckDetails"
                      :items="directionTrucktype2"
                      item-text="name"
                      return-object
                      dense
                      :label="$t('INPUTS.SELECT_TRUCK_TYPE')"
                      no-data-text="Malumot topilmadi"
                      outlined
                  ></v-select>
                </div>
              </div>
              <div class="row">
                <div class="col-4" style="padding-top: inherit; padding-top: inherit">
                  <v-text-field
                      style="padding-top: inherit; margin-top: inherit; margin-bottom: inherit; padding-bottom: 0px"
                      dense
                      v-model="truckId.price"
                      label="Narxi"
                      outlined
                  />
                </div>
                <div class="col-4" style="padding-top: inherit; padding-top: inherit">
                  <v-text-field
                      class="mb-0"
                      :value="numberWithSpaces(truckPriceNds)"
                      disabled
                      label="QQS"
                      dense
                      outlined
                  ></v-text-field>
                </div>
                <div class="col-4" style="padding-top: inherit; padding-top: inherit">
                  <v-text-field
                      class="mb-0"
                      disabled
                      dense
                      :value="numberWithSpaces(truckPriceWithNds)"
                      @input="truckPriceWithNds = arguments[0]"
                      label="Narx(QQS)"
                      outlined
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="row" style="height: 110px !important;">
              <div class="col-3" style="padding-top: inherit; margin-top: inherit; height: 60px !important;">
                <v-select
                    v-model="deliver"
                    :items="contractType"
                    dense
                    item-text="name"
                    item-value="value"
                    :label="$t('INPUTS.SUPPLIER')"
                    outlined
                ></v-select>
              </div>

              <div
                  class="col-3" style="margin-top: inherit; padding-top: inherit"
                  v-if="!(deliver == 'POS' || combobox.juridical_type == 'JI')"
              >
                <v-select
                    v-model="driverTruckId"
                    :items="truckDetails"
                    item-text="item"
                    dense
                    item-value="id"
                    outlined
                    :label="$t('INPUTS.SELECT_TRUCKTYPE')"
                    no-data-text="Malumot topilmadi"
                    return-object
                    @input="main_driver = assistant_driver = ''"
                ></v-select>
              </div>
              <div class="col-3" style="padding-top: inherit; margin-top: inherit" v-if="!(deliver == 'POS')">
                <v-autocomplete
                    :error-messages="main_driverErrors"
                    @input="$v.main_driver.$touch()"
                    @blur="$v.main_driver.$touch()"
                    v-model="main_driver"
                    :items="getZayavkaDriversList"
                    item-text="driver_full_name"
                    dense
                    item-value="id"
                    :label="$t('INPUTS.MAIN_DRIVER')"
                    outlined
                    no-data-text="Malumot topilmadi"
                    :loading="isLoading"
                ></v-autocomplete>
              </div>
              <div class="col-3" style="margin-top: inherit; padding-top: inherit" v-if="!(deliver == 'POS')">
                <v-autocomplete
                    v-model="assistant_driver"
                    :items="driverAssistant"
                    item-text="driver_full_name"
                    dense
                    :label="$t('INPUTS.MAIN_DRIVER_SECOND')"
                    item-value="id"
                    outlined
                    no-data-text="Malumot topilmadi"
                    :loading="isLoading"
                ></v-autocomplete>
              </div>
              <div class="col-6" style="padding-top: inherit; margin-top: inherit; height: 10px !important;">
                <v-text-field
                    v-model="post_kg"
                    outlined
                    :label="$t('INPUTS.KG_WEIGHT')"
                    :error-messages="
                    $v.post_kg.$dirty &&
                    !$v.post_kg.required &&
                    !post_kg && ['To\'ldirilishi shart bo\'lgan qator']
                  "
                    :succes="$v.post_kg.$dirty && post_kg"
                    @blur="$v.post_kg.$touch()"
                    @input="$v.post_kg.$touch()"
                    dense
                ></v-text-field>
              </div>
              <div class="col-6" style="margin-top: inherit; padding-top: inherit">
                <v-text-field
                    v-model="post_m3"
                    :label="$t('INPUTS.m3_WEIGHT')"
                    :error-messages="
                    $v.post_m3.$dirty &&
                    !$v.post_m3.required &&
                    !post_m3 && ['To\'ldirilishi shart bo\'lgan qator']
                  "
                    :succes="$v.post_m3.$dirty && post_m3"
                    @blur="$v.post_m3.$touch()"
                    @input="$v.post_m3.$touch()"
                    outlined
                    dense
                ></v-text-field>
              </div>
            </div>
            <div v-if="deliver !== 'POS'" style="padding-top: inherit; margin-top: inherit">
              <v-row justify="space-between">
                <v-col
                    cols="5"
                    class="border"
                    v-if="combobox.juridical_type == 'JI'"
                >
                  <label>Yuk mashina turi</label>
                  <v-autocomplete
                      outlined
                      hide-selected
                      :items="truckList"
                      item-text="truck__name"
                      item-value="truck_type.id"
                      dense
                      v-model="truck_1"
                      :loading="isLoading"
                  ></v-autocomplete>
                </v-col>
                <v-col
                    cols="5"
                    class="border"
                    v-if="combobox.juridical_type == 'JI'"
                >
                  <label>Yukmashinani tanlang</label>
                  <v-autocomplete
                      outlined
                      hide-selected
                      :items="truckTypeList"
                      item-text="item"
                      item-value="id"
                      dense
                      return-object
                      v-model="driverTruckId"
                      :loading="isLoading"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
            <div class="col-12 px-0" style="margin-top: inherit; padding-top: inherit">
              <div
                  v-if="deliver == 'POS'"
                  style="
                  border: 1px solid #333;
                  padding: 20px;
                  border-radius: 4px;
                "
              >
                <div
                    class="row mx-0 align-items-center justify-content-between"
                    style="padding-top: inherit; margin-top: inherit"
                >
                  <div>
                    <h3>
                      {{ $t('INPUTS.SUPPLIER_POS') }}
                    </h3>
                  </div>

                  <div>
                    <v-switch v-model="switch1" label="QQS"></v-switch>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3" style="margin-top: inherit; padding-top: inherit;">
                    <v-autocomplete
                        @change="getProviderContracts(postavshik)"
                        dense
                        v-model="postavshik"
                        :items="providers"
                        item-text="full_name"
                        item-value="id"
                        :label="$t('INPUTS.SUPPLIER')"
                        outlined
                        :loading="isLoading"
                    ></v-autocomplete>
                  </div>

                  <div class="col-3" style="padding-top: inherit; margin-top: inherit">
                    <v-text-field
                        v-currency="options"
                        :value="numberWithSpaces(postavshik_price) || 0"
                        :label="$t('TABLE_HEADER.PRICE')"
                        dense
                        outlined
                        disabled
                    ></v-text-field>
                  </div>
                  <div class="col-3" style="margin-top: inherit; padding-top: inherit">
                    <v-text-field
                        :value="numberWithSpaces(postavshik_nds_summa) || 0"
                        disabled
                        dense
                        :label="$t('TABLE_HEADER.NDS')"
                        outlined
                    ></v-text-field>
                  </div>

                  <div class="col-3" style="padding-top: inherit; margin-top: inherit">
                    <v-text-field
                        :label="$t('TABLE_HEADER.PRICE_WITH_NDS')"
                        v-model="postavshik_price_with_nds"
                        outlined
                        dense
                    ></v-text-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3" style="margin-top: inherit; padding-top: inherit">
                    <v-autocomplete
                        dense
                        v-model="postavshik_contract"
                        :items="providerContracts"
                        item-text="name"
                        item-value="id"
                        :label="$t('INPUTS.PROVIDER_CONTRACTS')"
                        outlined
                        no-data-text="Ma'lumotlat topilmadi"
                        :error-messages="providerContractErrors"
                        @input="$v.postavshik_contract.$touch()"
                        @blur="$v.postavshik_contract.$touch()"
                        :loading="isLoading"
                    ></v-autocomplete>
                  </div>

                  <div class="col-3" style="padding-top: inherit; margin-top: inherit">
                    <v-text-field
                        v-model="postavshik_driver"
                        label="Haydovchi ismi"
                        outlined
                        dense
                    ></v-text-field>
                  </div>
                  <div class="col-3" style="margin-top: inherit; padding-top: inherit">
                    <v-text-field
                        v-model="postavshik_truck_info"
                        outlined
                        label="Mashina modeli"
                        dense
                        :error-messages="
                        $v.postavshik_truck_info.$dirty &&
                        !$v.postavshik_truck_info.required &&
                        !postavshik_truck_info && [
                          'To\'ldirilishi shart bo\'lgan qator'
                        ]
                      "
                        :succes="
                        $v.postavshik_truck_info.$dirty && postavshik_truck_info
                      "
                        @blur="$v.postavshik_truck_info.$touch()"
                        @input="$v.postavshik_truck_info.$touch()"
                    ></v-text-field>
                  </div>

                  <div class="col-3" style="padding-top: inherit; margin-top: inherit">
                    <v-text-field
                        v-model="postavshik_truck_number"
                        class="truck__number__text__field"
                        outlined
                        label="Mashina raqami"
                        dense
                        :error-messages="
                        ($v.postavshik_truck_number.$dirty &&
                          !$v.postavshik_truck_number.required &&
                          !postavshik_truck_number && [
                            'To\'ldirilishi shart bo\'lgan qator'
                          ]) ||
                        ((!$v.postavshik_truck_number.minLength ||
                          !$v.postavshik_truck_number.maxLength) &&
                          postavshik_truck_number.replace(/( )/g, '').length <
                            8 && ['Mashina raqami 8 xonali bo\'lishi shart'])
                      "
                        :succes="
                        $v.postavshik_truck_number.$dirty &&
                        postavshik_truck_number
                      "
                        @blur="$v.postavshik_truck_number.$touch()"
                        @input="$v.postavshik_truck_number.$touch()"
                        v-mask="'XXXXXXXX'"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>

            <div
                class="my-5 row mx-0" style="padding-top: inherit; margin-top: inherit; height: 60px !important;"
            >
              <div class="col-3" style="padding-top: inherit; margin-top: inherit; margin: 0; padding-left: 0">
                <label> {{ $t('INPUTS.SHIPPING_DATE') }}</label>
                <v-dialog
                    ref="startDateDialog"
                    v-model="startDateModal"
                    :return-value.sync="start_date"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    append-icon="event"
                    readonly
                    :value="start_date | dateFilter"
                    v-bind="attrs"
                    dense
                    outlined
                    @input="$v.start_date.$touch()"
                    @blur="$v.start_date.$touch()"
                    v-on="on"
                  ></v-text-field>
                  </template>
                  <v-date-picker v-model="start_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn small color="error" @click="startDateModal = false"
                    >Bekor qilish</v-btn
                    >
                    <v-btn
                        small
                        color="success"
                        @click="$refs.startDateDialog.save(start_date)"
                    >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </div>

              <div class="col-3" style="margin-top: inherit; margin: 0; padding-top: 25px">
                <v-text-field
                    outlined
                    dense
                    :value="startTime"
                    :label="$t('INPUTS.SHIPPING_DATETIME')"
                    append-icon="access_time"
                    v-mask="'##:##'"
                    v-bind="attrs"
                    v-on="on"
                    @input="onCustomDateInput('startTime', arguments[0])"
                    width="290px"
                ></v-text-field>

              </div>

              <div class="col-3" style="margin-top: inherit; padding-top: inherit; margin: 0">
                <label>{{ $t('INPUTS.DELIVERY_DATE') }}</label>
                <v-dialog
                    ref="endDateDialog"
                    v-model="endDateModal"
                    :return-value.sync="end_date"
                    persistent
                    width="290px"
                    :class="'mb-5'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    append-icon="event"
                    readonly
                    :value="end_date | dateFilter"
                    v-bind="attrs"
                    dense
                    outlined
                    @input="$v.end_date.$touch()"
                    @blur="$v.end_date.$touch()"
                    v-on="on"
                  ></v-text-field>
                  </template>
                  <v-date-picker v-model="end_date" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn small color="error" @click="endDateModal = false"
                    >Bekor qilish</v-btn
                    >
                    <v-btn small color="success" @click="$refs.endDateDialog.save(end_date)"
                    >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </div>

              <div class="col-3" style="margin-top: inherit; padding-top: 25px; margin: 0; padding-right: 0">
                <v-text-field
                    outlined
                    dense
                    :value="endTime"
                    :label="$t('INPUTS.SHIPPING_DATETIME')"
                    @input="onCustomDateInput('endTime', arguments[0])"
                    append-icon="access_time"
                    v-mask="'##:##'"
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </div>

            </div>


            <div>
              <v-textarea
                  outlined
                  :label="$t('TABLE_HEADER.COMMENT')"
                  v-model="comment"
              ></v-textarea>
            </div>
            <div style="display: flex; justify-content: end;">
              <v-btn
                  color="success"
                  :disabled="zayavkaLoading"
                  class="btn btn-success"
                  type="submit"
                  
              >
                <i class="el-icon-loading" v-if="zayavkaLoading"></i>
                {{ $t('FORM.SUBMIT') }}
              </v-btn
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module'
import {
  required,
  requiredIf,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'
import {validationMixin} from 'vuelidate'
import Swal from 'sweetalert2'
import draggable from 'vuedraggable'
import {isNumberKey, numberWithSpaces, isValidTime, currencyOptions} from '@/utils'

export default {
  components: {draggable},
  mixins: [validationMixin],
  data() {
    return {
      zayavkaLoading: false,
      dformat: '',
      enddateformat: '',
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      truckIdPrice: '',
      truck_1: '',
      comment: '',
      switch1: true,
      switch2: true,
      disabled: false,
      flat: false,
      readonly: false,
      landscape: false,
      ampmInTitle: false,
      useSeconds: false,
      format: '24h',
      fullWidth: false,
      noTitle: false,
      scrollable: false,
      // modal2: false,
      // modal3: false,
      // menu3: false,
      postavshik_driver: '',
      postavshik_truck_info: '',
      //
      postavshik_truck_number: '',
      //
      postavshik_contract: '',
      postavshik: '',
      postavshik_price: '',
      postavshik_nds_summa: '',
      postavshik_price_with_nds: '',
      providerContract: '',
      provider: '',
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      startDateModal: false,
      endDateModal: false,
      menu2: false,
      combobox: '',
      truckId: '',
      combobox2: '',
      driverTruckId: '',
      citydestination: [],
      zayavka_status: 'OTB',
      deliver: '',
      price: '',
      price_with_nds: '',
      start_date: new Date().toISOString().substr(0, 10),
      end_date: new Date().toISOString().substr(0, 10),
      d: '',
      startTime: '',
      endDate: '',
      endTime: '',
      post_kg: '',
      post_m3: '',
      main_driver: '',
      assistant_driver: '',
      contractType: [
        {name: 'BTS', value: 'BTS'},
        {name: 'Boshqalar', value: 'POS'}
      ],
      items: [
        {name: 'Naqd', value: 'NAQD'},
        {name: 'Shartnoma', value: 'CONT'}
      ],
      cities: [],
      cities2: [{icon: 'name'}],
      city: '',
      delivered_date: '',
      contract: '',
      truck: '',
      direction_truck: '',
      value: null,
      selectedCurrencyOption: 1,
      currencyCode: 'USD',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      minActive: false,
      maxActive: false,
      autoDecimalMode: false,
      valueAsInteger: false,
      allowNegative: false,
      placement_expenditure: null,
      directionTrucktype: []
    }
  },
  validations() {
    return {
      main_driver: {
        required: requiredIf(function () {
          return ['BTS'].includes(this.deliver)
        })
      },
      postavshik_contract: {
        required: requiredIf(function () {
          return ['POS'].includes(this.deliver)
        })
      },
      combobox2: {
        required
      },
      postavshik_truck_info: {
        required: requiredIf(function () {
          return ['POS'].includes(this.deliver)
        })
      },
      postavshik_truck_number: {
        required: requiredIf(function () {
          return ['POS'].includes(this.deliver)
        }),
        minLength: minLength(8),
        maxLength: maxLength(8)
      },

      post_kg: {
        required
      },
      post_m3: {
        required
      }
    }
  },
  filters: {
    mask: (val) => {
      if (val !== undefined) {
        return val.toLocaleString('es-US')
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch('getNds')
    this.$store.dispatch('getAllCities')
    this.$store.dispatch('getTruckLists')
    this.$store.dispatch('getAllDrivers')
    this.$store.dispatch('getZayavkaDrivers')
    this.$store.dispatch('getAllProvidersWithoutPg')
    this.$store.commit('setAllClientsName', [])
  },
  computed: {
    getZayavkaDriversList() {
      return this.$store.state.requests.ZayavkaDriversList
    },
    dFormatted() {
      return this.formatDate(this.dformat)
    },
    providerContractErrors() {
      const errors = []
      if (!this.$v.postavshik_contract.$dirty) return errors
      !this.$v.postavshik_contract.required &&
      errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },

    clientContractErrors() {
      const errors = []
      if (!this.$v.combobox2.$dirty) return errors
      !this.$v.combobox2.required &&
      errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },

    endDateFormatted() {
      return this.formatDate(this.end_date)
    },
    truckList() {
      return this.$store.state.requests.allTruckList || []
    },
    truckTypeList() {
      let data = this.$store.state.requests.allTruckList
      data = data.filter(
          (x) => x.truck_type.id == this.truck_1 && x.status !== 'BRO'
      )
      data.forEach((element) => {
        element.item = element.reg_number_region + ' ' + element.reg_number
      })
      return data
    },
    AllCities() {
      let data = this.$store.state.requests.Cities
      if (this.city.id) data = data.filter((x) => x.id !== this.city.id)
      return data
    },
    options() {
      return {
        locale: 'es-US',
        currency: [
          this.currencyCode,
          null,
          {prefix: this.prefix, suffix: this.suffix}
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
            ? {min: this.valueRange[0], max: this.valueRange[1]}
            : undefined,
        precision: this.precisionEnabled
            ? this.precisionRangeEnabled
                ? {min: this.precisionRange[0], max: this.precisionRange[1]}
                : this.precisionFixed
            : undefined,
        distractionFree: this.distractionFree
            ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
            : false,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    providers() {
      return this.$store.state.requests.allProvidersWithoutPg
    },
    main_driverErrors() {
      const errors = []
      if (!this.$v.main_driver.$dirty) return errors
      !this.$v.main_driver.required &&
      errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    allDrivers() {
      return this.$store.state.requests.allDrivers
    },
    driverAssistant() {
      const data = this.$store.state.requests.ZayavkaDriversList
      return data.filter((x) => x.id !== this.main_driver)
    },
    providerContracts() {
      const data = this.$store.state.requests.providerContract
      if (data.length !== 0) {
        data.forEach((element) => {
          element.name =
              'Raqami ' +
              element.contract_number +
              ' sanasi: ' +
              element.start_date
        })
      }
      return data
    },
    allClientNames() {
      return this.$store.state.requests.allClientsName
    },
    contractNumbers() {
      let data = this.$store.state.requests.oneClientContract
      return data
    },
    directionTrucktype2() {
      let data = this.directionTrucktype
      if (data.prices) {
        data.prices.forEach((element) => {
          element.is_ref_name = element.is_ref ? 'Ref' : 'Oddiy'
          // eslint-disable-next-line no-extra-boolean-cast
          // if (Boolean(element.price) && typeof element.price !== 'string')
          //   element.price = +element.price || 0
          element.name =
              element.trucktype_description.brand.name +
              ' - ' +
              element.trucktype_description.model +
              '(' +
              element.trucktype_description.capacity_kg +
              ' kg' +
              element.trucktype_description.capacity_m3 +
              'm3' +
              ')' +
              ' ' +
              element.is_ref_name
        })
      }
      // }
      // }
      return data.prices
    },
    contractDetails() {
      // var data = this.$store.state.requests.allMarshrut
      // if (data.marshrut_description)
      //   data.marshrut_description.forEach(x => {
      //     x.cities
      //   })
      // console.log('data', data)
      return this.$store.state.requests.allMarshrut.marshrut_description
    },
    truckPriceNds() {
      // // eslint-disable-next-line no-extra-boolean-cast
      // if (!Boolean(this.truckId.price)) {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.truckId.price = 0
      // }
      return Math.round(
          (this.toFloat(this.truckId.price || 0) * this.nds) / 100
      )
    },
    truckPriceNds1() {
      // // eslint-disable-next-line no-extra-boolean-cast
      // if (!Boolean(this.truckIdPrice)) {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.truckIdPrice = 0
      // }
      return (this.toFloat(this.truckIdPrice || 0) * this.nds3) / 100
    },
    nds() {
      return this.truckId.nds ? this.$store.state.requests.nds.nds : 0
    },
    nds2() {
      return this.switch1 ? this.$store.state.requests.nds.nds : 0
    },
    nds3() {
      let data1
      if (this.switch2) {
        data1 = this.$store.state.requests.nds.nds
      } else {
        data1 = 0
      }
      return data1
    },
    truckPriceWithNds() {
      const total =
          this.toFloat(this.truckId.price) + this.toFloat(this.truckPriceNds)
      if (total) return total
      else return 0
    },
    truckPriceWithNds1() {
      const total =
          this.toFloat(this.truckIdPrice) + this.toFloat(this.truckPriceNds1)
      if (total) return total
      else return 0
    },
    truckDetails() {
      const data = this.$store.state.requests.truckDetails
      data.forEach((element) => {
        element.item = element.reg_number
      })
      return this.$store.state.requests.truckDetails
    }
  },
  watch: {
    combobox2: function () {
      this.truckIdPrice = ""
      this.directionTrucktype = []
      this.main_driver = ''
      this.clearDatasOnChangeZayavkaContract()
    },
    dformat: function () {
      this.dateFormatted = this.formatDate(this.dformat)
    },
    enddateformat: function () {
      this.dateFormatted = this.formatDate(this.end_date)
    },
    citydestination(val) {
      if (this.contractDetails) {
        this.contractDetails.forEach((x) => {
          // console.log('x', x, this.citydestination)
          // console.log(
          //   JSON.stringify(
          //     val
          //       .filter(x => Boolean(x.city_id))
          //       .map(dastionation => dastionation.city_id)
          //   ),
          //   JSON.stringify(x.cities.map(city => city.city_name.id))
          // )
          if (
              this.arrayEquals(
                  val
                      .filter((x) => Boolean(x.city_id))
                      .map((dastionation) => dastionation.city_id),
                  x.cities.map((city) => city.city_name.id)
              )
          ) {
            this.directionTrucktype = x
          }
        })
      }
      // console.log('this.directionTrucktype', this.directionTrucktype)
    },
    cities2() {
      this.city = ''
    },
    driverTruckId: function (val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val) && val.driver) {
        this.main_driver = val.driver.id
      }
    },
    combobox: function () {
      this.combobox2 = ''
      this.clearDatasOnChangeZayavkaContract()
    },
    postavshik_price_with_nds(val) {
      // console.log('postavshik_price_with_nds', val)
      // if (val) {
      //   console.log(val, this.toFloat(val), this.nds2)
      //   this.postavshik_nds_summa = (this.toFloat(val) * this.nds2) / 100
      //   this.postavshik_price_with_nds =
      //     this.toFloat(val) + this.postavshik_nds_summa
      // } else {
      //   this.postavshik_price_with_nds = '0'
      //   this.postavshik_nds_summa = '0'
      // }

      if (val) {
        this.calcPriceAndNDSSumma(val)
        return
      }

      this.postavshik_price = 0
      this.postavshik_nds_summa = 0
    },
    switch1(val) {
      if (!val) {
        this.postavshik_price = this.postavshik_price_with_nds
        this.postavshik_nds_summa = 0
        return
      }

      this.calcPriceAndNDSSumma(this.postavshik_price_with_nds)
      // this.postavshik_nds_summa =
      //   (this.toFloat(this.postavshik_price) * this.nds2) / 100
      // this.postavshik_price_with_nds = parseInt(
      //   this.toFloat(this.postavshik_price) + this.postavshik_nds_summa
      // )
    }
  },
  methods: {
    isNumberKey,
    numberWithSpaces,
    currencyOptions,

    clearDatasOnChangeZayavkaContract() {
      this.citydestination = []
      this.truckId = ''
      this.assistant_driver = ''
      this.comment = ''
      this.driverTruckId = ''
      this.deliver = ''
      this.truck_1 = ''
      this.post_kg = ''
      this.post_m3 = ''
      this.startTime = ''
      this.endTime = ''
      this.dformat = ''
      this.enddateformat = ''
    },

    onCustomDateInput(key, arg) {
      // console.log('onCustomDateInput', key, arg)
      if (arg.length >= 10) {
        let [day, month, year] = arg.split('-')

        this[key] = `${year}-${+month <= 12 ? month : '01'}-${day}`
      } else if (~arg.indexOf(':')) {
        this[key] = arg
      }
    },

    calcPriceAndNDSSumma(val) {
      const total_nds = this.toFloat(val)
      const start = this.toFloat(Math.round(total_nds / (1 + this.nds2 / 100)))
      const different = Math.round(total_nds) - Math.round(start)

      this.postavshik_price = start
      this.postavshik_nds_summa = different
    },

    arrayEquals(a, b) {
      return (
          Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index])
      )
    },
    setCities() {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(this.city)) {
        this.cities.push(this.city)
      }
    },
    toFloat(val) {
      if (typeof val == 'string') {
        return parseInt(val.replace(/[ ,]/g, ''), 10)
      } else if (typeof val == 'number') return val
      else return 0
    },
    getByOrder(val, index) {
      const data = val
          .filter((item) => {
            if (item.cities.find((x) => x.order == index)) {
              if (this.citydestination[index - 1]) {
                if (
                    item.cities[index - 2].city ==
                    this.citydestination[index - 1].city_id
                ) {
                  return true
                } else false
              } else return true
            } else return false
          })
          .map((item) => {
            // console.log('123321', item.id)
            const data = {
              direction_id: item.id,
              id: item.cities[index - 1].id,
              prices: item.prices,
              city_id: item.cities[index - 1].city_name.id,
              name: item.cities[index - 1].city_name.name,
              order: item.cities[index - 1].order
            }
            return data
          })
      return data
    },
    maxCityNumber(val) {
      let count = 0
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        count = Math.max(
            ...val.map((x) => Math.max(...x.cities.map((x) => x.order)))
        )
      }
      return count
    },
    getProviderContracts(val) {
      if (val !== '' && val !== undefined) {
        this.$store.dispatch('getProvidersContractById', val)
      }
    },
    getClientContracts() {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(this.combobox.id)) {
        this.$store.dispatch('getOneClientContract', this.combobox.id)
      }

    },
    aa(target) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!Boolean(target)) target = '0'
      this.truckPrices.price = parseFloat(target.replace(/,/g, ''), 10)
      this.truckPrices.nds_amout =
          (parseFloat(target.replace(/,/g, ''), 10) * this.nds.nds) / 100
      this.truckPrices.price_with_nds =
          this.truckPrices.nds_amout + this.truckPrices.price
    },
    myFn(val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        const data = {
          name: val
        }
        this.$store.dispatch('clientSearch', data)
      }
    },
    submitzayavka() {
      console.log(this.$dayjs(this.end_date).isValid(), 'this.end_date.IsValid');
      if (
          !(
              this.start_date && this.$dayjs(this.start_date).isValid()
          ) ||
          !(this.end_date && this.$dayjs(this.end_date).isValid())
      ) {
        return Swal.fire({
          title: "Xato to'ldirilgan",
          text: "Yuk ketish yoki yetib borish sanasi format to'g'ri emas !",
          icon: 'error'
        })
      }

      if (
          this.startTime &&
          this.endTime &&
          (!isValidTime(this.startTime) ||
              !isValidTime(this.endTime) ||
              [this.startTime, this.endTime].some(
                  (timeInput) => !~timeInput.indexOf(':') || timeInput.length < 5
              ))
      ) {
        return Swal.fire({
          title: "Xato to'ldirilgan",
          text: "Yuk ketish yoki yetib borish vaqti format to'g'ri emas !",
          icon: "error"
        })
      }

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato toldirilgan',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error'
        })
      } else {
        try {
          this.zayavkaLoading = true
          let id = ''
          if (this.combobox.juridical_type !== 'JI') {
            id = this.directionTrucktype.id
          }
          const data = {
            fizik_contract_direction: [],
            zayavka_status: this.zayavka_status,
            deliver: this.deliver,

            start_date: this.start_date + 'T' + this.startTime,
            end_date: this.end_date + 'T' + this.endTime,
            post_kg: parseFloat(this.post_kg),
            post_m3: parseFloat(this.post_m3),
            delivered_date: null,
            contract: this.combobox2,
            truck: this.driverTruckId.id,
            main_driver: this.main_driver,
            assistant_driver: this.assistant_driver,
            direction: id,
            postavshik_truck_info: this.postavshik_truck_info,
            postavshik_truck_number: this.postavshik_truck_number
                .replace(/( )/g, '')
                .toUpperCase(),
            postavshik_driver: this.postavshik_driver,
            postavshik_price_with_nds: parseFloat(
                this.postavshik_price_with_nds.replace(/[ ,]/g, ''),
                10
            ),
            postavshik_nds_summa: this.postavshik_nds_summa,
            postavshik_price: parseFloat(
                this.postavshik_price &&
                this.postavshik_price.toString().replace(/[ ,]/g, ''),
                10
            ),
            postavshik_contract: this.postavshik_contract,
            postavshik: this.postavshik,
            comment: this.comment
          }
          if (this.combobox.juridical_type == 'JI') {
            data.fizik_contract_direction = [
              ...this.cities.map((x) => {
                const data = {
                  city: x.id,
                  order: x.order
                }
                return data
              })
            ]
            delete data.direction
            data.price = this.toFloat(this.truckIdPrice)
            data.nds_summa = this.toFloat(this.truckPriceNds1)
            data.price_with_nds = this.toFloat(this.truckPriceWithNds1)
          } else {
            data.price = this.toFloat(this.truckId.price)
            data.nds_summa = this.toFloat(this.truckPriceNds)
            data.price_with_nds = this.toFloat(this.truckPriceWithNds)
          }
          if (this.startTime == '') {
            let todayTime = new Date().toLocaleTimeString('uz-Uz', {
              hour24: false,
              hour: 'numeric',
              minute: 'numeric'
            })
            data.start_date = this.start_date + 'T' + todayTime
          }
          // eslint-disable-next-line no-extra-boolean-cast
          if (!Boolean(this.endTime)) {
            data.enddateformat = ''
          }
          if (data.deliver == 'BTS') {
            if (this.truck == '' && this.truck == null) {
              Swal.fire({
                title: 'Xato malumot toldirilgan',
                text: "Ma'lumotlar yuborilmadi!",
                icon: 'error',
                confirmButtonClass: 'btn btn-secondary'
              })
            } else {
              delete data.postavshik_truck_info
              delete data.postavshik_driver
              delete data.postavshik_price_with_nds
              delete data.postavshik_nds_summa
              delete data.postavshik_price
              delete data.postavshik_contract
              delete data.postavshik
              this.$store.dispatch('createZayavka', data)
            }
          }
          if (data.deliver == 'POS') {
            delete data.main_driver
            delete data.assistant_driver
            data.truck = null

            // console.log('data', data)
            this.$store
                .dispatch('createZayavka', data)
                .then(() => {
                  console.log('Done !')
                  this.zayavkaLoading = false
                })
                .catch((err) => {
                  this.zayavkaLoading = false
                  console.error(err)
                })
          }
        } catch (err) {
          console.error(err)
          this.zayavkaLoading = false
          Swal.fire({
            title: 'Xato malumot toldirilgannnn',
            text: "Ma'lumotlar yuborilmadi!",
            icon: 'error',
            confirmButtonClass: 'btn btn-secondary'
          })
        }
      }
      // }
    },
    clientContractsDetail(payload) {
      this.$store.dispatch('getClientContract', payload)
    },
    getTruckDetails() {
      this.$store.dispatch(
          'getTruckEmptyById',
          this.truckId.trucktype_description.id
      )
    }

    // onPriceChange(arg) {
    //   console.log('onPriceChange', arg)

    //   if (!isNaN(arg)) {
    //     this.truckId.price = +arg
    //   }
    // }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('INPUTS.ORDER_REGISTRATION')}
    ])

    this.$store.commit('setAllClientsName', [])
  },
  beforeDestroy() {
    this.$store.commit('setAllClientsName', [])
  }
}
</script>
<style scoped></style>
